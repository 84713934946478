import './full-height-mobile.scss';
var moduleName = 'fullHeightMobile';
angular.module(moduleName, []).directive('fullHeightMobile', ['$window', '$document', function ($window, $document) {
  return {
    controller: function FullHeightMobileCtrl() {
      var ctrl = this;
      var root = $document[0].querySelector(':root');

      ctrl.onOrientationChangeHandler = function () {
        root.style.setProperty('--min-full-height', $window.innerHeight + 'px');
      };
    },
    link: function link(scope, element, attrs, fullHeightMobileCtrl) {
      fullHeightMobileCtrl.onOrientationChangeHandler();
      $window.addEventListener("resize", fullHeightMobileCtrl.onOrientationChangeHandler);
    }
  };
}]);
export default moduleName;